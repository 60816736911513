import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useUserContext } from "@/context/UserContext";
import SmallNavItem from "../../data/header.json";

const SmallNav = () => {
  const router = useRouter();
  const { userLoggedIn } = useUserContext();
  const isActive = (href) => router.pathname === href;
  return (
    <>
      <nav className="mainmenu-nav">
        <ul className="dashboard-mainmenu rbt-default-sidebar-list">
          <li>
            <Link href="/">
              <i className="feather-monitor"></i>
              <span>Welcome</span>
            </Link>
          </li>
          <li>
          {userLoggedIn ? (
                      <Link
                        className={isActive("/plans-billing") ? "active" : ""}
                        href="/plans-billing"
                      >
                        <i className="feather-briefcase"></i>
                        <span>Manage Subscription</span>
                      </Link>
                      ) : (
                        <Link
                        className={isActive("/SignUp") ? "active" : ""}
                        href="/SignUp"
                      >
                        <i className="feather-briefcase"></i>
                        <span>Sign Up</span>
                      </Link>
                      )}
          </li>
        </ul>
        <div className="rbt-sm-separator"></div>
        <ul className="dashboard-mainmenu rbt-default-sidebar-list">
          {SmallNavItem &&
            SmallNavItem.smallNavItem.slice(0, 7).map((data, index) => (
              <li key={index}>
                <Link
                  // className={data.isDisable ? "disabled" : ""}

                  className={
                    isActive(data.link)
                      ? "active"
                      : "" || data.isDisable
                      ? "disabled"
                      : ""
                  }
                  href={data.link}
                >
                  <Image
                    src={data.img}
                    width={35}
                    height={35}
                    alt="AI Generator"
                  />
                  <span>{data.text}</span>
                  {data.badge !== "" ? (
                    <div className="jureeltd-badge-card badge-sm ml--10">
                      {data.badge}
                    </div>
                  ) : (
                    ""
                  )}
                </Link>
              </li>
            ))}
        </ul>
        <div className="rbt-sm-separator"></div>
        <div className="mainmenu-nav">
        <ul className="dashboard-mainmenu rbt-default-sidebar-list">
          {SmallNavItem &&
            SmallNavItem.smallNavItem
              .slice(7, 14)
              .map((data, index) => (
                <li key={index}>
                  <Link href={data.link}>
                    <i className={`feather-${data.icon}`}></i>
                    <span>{data.text}</span>
                  </Link>
                </li>
              ))}
        </ul>

          <div className="rbt-sm-separator"></div>
          <ul className="dashboard-mainmenu rbt-default-sidebar-list">
            <li>
              <Link href="/release-notes">
                <i className="feather-bell"></i>
                <span>Release notes</span>
              </Link>
            </li>
            <li>
              <Link href="/terms-of-use">
                <i className="feather-briefcase"></i>
                <span>Terms of Use</span>
              </Link>
            </li>
            <li>
              <Link href="/privacy-policy">
                <i className="feather-briefcase"></i>
                <span>Privacy Policy</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default SmallNav;
